import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    serviceFormOptions: [],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SERVICE_FORMS(state, data) {
      state.serviceFormOptions = data
    },
    SET_WORK_FLOWS(state, data) {
      state.calendarOptions = data
      const idArray = []
      data.forEach(category => {
        category.workFlows.forEach(item => {
          idArray.push(item._id)
        })
      })
      state.selectedCalendars = idArray
    },
  },
  actions: {
    fetchWorkFlows({ commit }) {
      return axios
        .get('operation/calendar/respond-with/work-flows')
        .then(response => {
          commit('SET_WORK_FLOWS', response.data.workFlows)
          // commit('SET_SERVICE_FORMS', response.data.serviceForms)
          return response
        })
    },
    fetchEvents(ctx, {
      calendars, view, startDate, endDate,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('operation/service-tasks/calendar-view', {
            calendars,
            view,
            startDate,
            endDate,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
