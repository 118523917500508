<template>
  <div class="app-calendar">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="5"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title calendar_title float-left pr-1 mb-0">
              Calendar <span class="d-block d-md-none">
                <b-button
                  variant="success"
                  @click="showCreateSidebar"
                >
                  <span class="align-middle">New Service Request</span>
                </b-button>

                <b-button
                  variant="primary"
                  class="printer_icon"
                  @click="openDateModal"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    class=""
                    size="16"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Send Summary to Telegram'"
                  variant="primary"
                  class="printer_icon"
                  @click="openTelegramModal"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    class=""
                    size="16"
                  />
                </b-button>
              </span>
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="7"
        cols="12"
      >
        <b-button
          variant="success"
          @click="showCreateSidebar"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Create New Service Request</span>
        </b-button>

        <b-button
          variant="primary"
          class="printer_icon"
          @click="openDateModal"
        >
          <feather-icon
            icon="DownloadIcon"
            class=""
            size="16"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover.top="'Send Summary to Telegram'"
          variant="primary"
          class="printer_icon"
          @click="openTelegramModal"
        >
          <feather-icon
            icon="MessageSquareIcon"
            class=""
            size="16"
          />
        </b-button>
      </b-col>
    </b-row>
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :height="calendarHeight" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0 calendar_pb_none">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
              <template #eventContent="arg">
                <div>
                  <b-row v-if="arg.event.extendedProps.assignees && arg.event.extendedProps.assignees.length">
                    <b-col
                      cols="12"
                      md="8"
                    >
                      {{ arg.event.title }}
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <div
                        v-if="arg.event.extendedProps.assignees.length > 1"
                        class="w-100 ml-auto"
                      >
                        <b-avatar-group
                          size="sm"
                          class="float-right"
                        >
                          <b-avatar
                            v-for="(user, index) in arg.event.extendedProps.assignees"
                            :key="index"
                            v-b-tooltip.hover
                            variant="success"
                            :title="user.name"
                            :src="user.image"
                            :text="resolveAcronymName(user.name)"
                          />
                        </b-avatar-group>
                      </div>
                      <div
                        v-else
                        class="w-100 ml-auto text-right"
                      >
                        <b-avatar
                          size="sm"
                          variant="success"
                          :src="arg.event.extendedProps.assignees[0].image"
                          :text="resolveAcronymName(arg.event.extendedProps.assignees[0].name)"
                        />
                        <span class="ml-50">{{ arg.event.extendedProps.assignees[0].name }}</span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <span
                        :class="{
                          'calendar-title': refCalendar.getApi().view.type == 'dayGridMonth',
                          'white-title': (arg.event.title.includes('Awaiting Approval') || (arg.event.title.includes('AWAITING APPROVAL') && refCalendar.getApi().view.type == 'timeGridDay')),
                          'red-title': (arg.event.title.includes('AWAITING APPROVAL') && refCalendar.getApi().view.type == 'listMonth')
                        }"
                      >
                        {{ arg.event.title }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :clear-event-data="clearEventData"
      />
      <event-detail-view
        v-model="isEventDetailSidebarActive"
        :event="eventDetail"
        @showEmailComposer="showEmailComposer"
        @updateEventInCalendar="updateEventInCalendar"
      />
    </div>

    <!-- <print-view
      ref="requestPrintView"
    /> -->

    <b-modal
      id="modal-date-select"
      size="sm"
      title="Download Calendar in PDF"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="printCalendar"
      @cancel="closeDateModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Select a Date"
            label-for="date"
          >
            <b-input-group>
              <flat-pickr
                id="date"
                v-model="date"
                class="form-control flat-pickr-group"
                placeholder="Date"
                :config="flatPickrConfig"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!date"
          @click="ok()"
        >
          <span class="align-middle">Download</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-telegram-date-select"
      size="sm"
      title="Send Summary to Telegram"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="sendMessage"
      @cancel="closeTelegramModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Select a Date"
            label-for="date"
          >
            <b-input-group>
              <flat-pickr
                id="date"
                v-model="date"
                class="form-control flat-pickr-group"
                placeholder="Date"
                :config="flatPickrConfig"
                @input="getData()"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="d-flex justify-content-between mb-75">
            <span class="label">Message Preview</span>
            <span
              class="label-link"
              @click="generateMessage()"
            >
              Reset to original
            </span>
          </div>
          <b-form-group
            label=""
            label-for="event-message"
          >
            <div class="message-editor telegram">
              <quill-editor
                id="telegram-quil-content"
                v-model="message"
                class="editor"
                :options="editorOption"
              />
            </div>
            <div
              id="quill-toolbar"
              class="d-flex border-bottom-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-link" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!date || !message"
          @click="ok()"
        >
          <span class="align-middle">Send</span>
        </b-button>
      </template>
    </b-modal>

    <email-compose
      :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
      :service-form="eventDetail"
      @updateEventInCalendar="updateEventInCalendar"
    />
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BAvatarGroup, BAvatar, VBTooltip, BInputGroup, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import {
  onMounted, onUnmounted, nextTick, ref,
} from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import EventDetailView from './calendar-event-handler/EventDetailView.vue'
import EmailCompose from './calendar-event-handler/EmailCompose.vue'
// import PrintView from './PrintView.vue'
import useCalendar from './useCalendar'
import { fetchWorkFlows } from './useWorkFlows'

// Notification

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    EventDetailView,
    EmailCompose,
    // PrintView,

    BRow,
    BButton,
    BCol,
    BAvatarGroup,
    BAvatar,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    quillEditor,

    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      date: '',
      message: '',
      chatID: '',
      venueFieldName: '',
      serviceRequests: [],
      venueOptions: [],
      groupData: [],
      offeringField: [],
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Message',
      },
      shallShowEmailComposeModal: false,
    }
  },
  methods: {
    getImage(user) {
      return (user.image ? user.image : '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    openTelegramModal() {
      const calendarApi = this.refCalendar.getApi()
      this.date = moment(calendarApi.view.currentStart).format('DD/MM/YYYY') || ''
      this.getData()
      this.$root.$emit('bv::show::modal', 'modal-telegram-date-select', '')
    },
    openDateModal() {
      const calendarApi = this.refCalendar.getApi()
      this.date = moment(calendarApi.view.currentStart).format('DD/MM/YYYY') || ''
      this.$root.$emit('bv::show::modal', 'modal-date-select', '')
    },
    oldPrintCalendar() {
      this.$refs.requestPrintView.getDataToDownload(this.date, store.state.operationCalendar.selectedCalendars)
    },
    getData() {
      const formData = new FormData()
      formData.append('date', this.date)

      this.$http.post('operation/calendar/day-events', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.chatID = response.data.chatID
          this.venueFieldName = response.data.venueField
          this.offeringField = response.data.offeringField.split(',')
          // if (response.data.serviceDescription && response.data.serviceDescription.customFields) {
          //   const venueObject = response.data.serviceDescription.customFields.find(o => o.label === response.data.venueField)
          //   if (venueObject) {
          //     this.venueOptions = venueObject.extraOptions
          //   }
          // }
          this.serviceRequests = response.data.serviceRequests
          this.groupData = response.data.serviceRequests.reduce((rv, x) => {
            const v = x.serviceForm._id
            const {
              stringID, paymentStatus, paymentRemarks, agentName, agentCode,
            } = x.serviceForm
            const el = rv.find(r => r && r.form === v)
            if (el) {
              el.values.push(x)
            } else {
              rv.push({
                form: v, stringID, paymentStatus, paymentRemarks, agentName, agentCode, values: [x],
              })
            }
            return rv
          }, [])
          this.generateMessage()
        })
    },
    generateMessage() {
      this.message = `<p><strong>${this.fullMonthDateFormat(this.date)}</strong></p>
          <p><strong>Ceremonial Manpower Arrangement</strong></p>
          <p><br></p><p><strong>-- Service Requests --</strong></p>`
      this.groupData.forEach(item => {
        this.message += `<p><br></p><p><a href="${window.location.origin}/operations/service-forms/${item.form}/show" rel="noopener noreferrer" target="_blank">
              <strong>Service Form ${item.stringID}</strong></a></p>
              <p><strong>Payment:</strong> ${item.paymentStatus || '-'} (${item.paymentRemarks || '-'})</p>
              <p><strong>Agent:</strong> ${item.agentName || '-'} (${item.agentCode || '-'})</p>`
        item.values.forEach(val => {
          this.message += `<p><br></p><p><a href="${window.location.origin}/operations/service-requests/${val._id}/show" rel="noopener noreferrer" target="_blank">${val.stringID}</a> - ${val.workFlow.name}</p>
                <p>${val.location || '-'} - ${this.timeFormatFromDateString(val.date)}</p><p>${val.assignees.map(a => a.name).join(', ') || '-'}</p>
                <p><strong>Remarks:</strong> ${val.remarks || '-'}</p>`
        })
      })

      this.message += '<p><br></p><p><strong>-- Venue Rentals --</strong></p>'
      const venueGroup = this.serviceRequests.reduce((rv, x) => {
        const v = x.date
        const venue = this.getLocationFromDescription(x.serviceDescription)
        // console.log(venue)
        if (venue) {
          const el = rv.find(r => r && r.venue === venue)
          if (el) {
            const objIndex = el.times.findIndex((obj => obj.date === v))
            if (objIndex !== -1) {
              el.times[objIndex].count += 1
              el.times[objIndex].links.push(`<a href="${window.location.origin}/operations/service-requests/${x._id}/show" rel="noopener noreferrer" target="_blank">${x.stringID}</a>`)
            } else {
              const timeObject = {
                date: v,
                count: 1,
                links: [`<a href="${window.location.origin}/operations/service-requests/${x._id}/show" rel="noopener noreferrer" target="_blank">${x.stringID}</a>`],
              }
              el.times.push(timeObject)
            }
          } else {
            const timeObject = {
              date: v,
              count: 1,
              links: [`<a href="${window.location.origin}/operations/service-requests/${x._id}/show" rel="noopener noreferrer" target="_blank">${x.stringID}</a>`],
            }
            rv.push({
              venue, times: [timeObject],
            })
          }
        }
        return rv
      }, [])
      venueGroup.forEach(item => {
        this.message += `<p><br></p><p><strong>${item.venue}</strong></p>`
        item.times.forEach(val => {
          this.message += `<p>${this.timeFormatFromDateString(val.date)} - ${val.count || '0'} (${val.links.join(', ')})`
        })
      })

      this.message += '<p><br></p><p><strong>-- Kitchen Orders --</strong></p><p><br></p>'
      const offeringGroup = []
      this.offeringField.forEach(key => {
        // let count = 0
        this.serviceRequests.forEach(req => {
          const offer = this.getOfferFromDescription(req.serviceDescription, key)
          if (offer) {
            const exist = offeringGroup.findIndex(o => o.offer === offer.key)
            if (exist === -1) {
              offeringGroup.push({
                offer: offer.key,
                count: offer.value,
              })
            } else {
              offeringGroup[exist].count -= -offer.value
            }
            // count -= -offer
          }
        })
        // if (count > 0) {
        //   offeringGroup.push({
        //     offer: key,
        //     count,
        //   })
        // }
      })
      offeringGroup.forEach(item => {
        this.message += `<p><strong>${item.offer}</strong> - ${item.count || '0'}</p>`
      })

      this.message += '<p><br></p><p><strong>-- Other Notes --</strong></p><p><br></p><p>N/A</p>'
    },
    getLocationFromDescription(data) {
      if (data && data.length) {
        const venue = data.find(o => o.key === this.venueFieldName)
        if (venue && Array.isArray(venue.value) && venue.value.length) {
          return venue.value[0]
        }
      }
      return ''
    },
    getOfferFromDescription(data, key) {
      if (data && data.length) {
        const offer = data.find(o => o.key.includes(key))
        if (offer && offer.value && offer.value > 0) {
          return offer
        }
      }
      return ''
    },
    printCalendar() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operation/download/service-task?date=${this.date}&selectedWorkflows=${JSON.stringify(store.state.operationCalendar.selectedCalendars)}`)
      formData.append('landscape', false)
      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Calendar of ${this.dateFormatWithDay(this.date)}`
          link.click()
        })
    },
    sendMessage() {
      const telegramURL = 'https://api.telegram.org/bot'
      const botToken = process.env.VUE_APP_TELEGRAM_BOT_TOKEN
      // const chatID = '-1001214367772'
      // const pageURL = `[For Service Request ${this.serviceRequestString}](${window.location.href})`
      // console.log(window.location.href)
      const message = this.message
        .replace(/(<p>)/igm, '')
        .replace(/<\/p>/igm, '%0A')
        .replace(/<br>/igm, '')
        .replace(/&amp;/igm, '%26amp;')
      // const message = encodeURI(replacedText)
      const finalURL = `${telegramURL}${botToken}/sendMessage?chat_id=${this.chatID.trim()}&text=${message}&parse_mode=HTML`
      this.$http.get(finalURL)
        .then(result => {
          if (result.data.ok === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Message Sent',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.description,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDateModal() {
      this.$root.$emit('bv::hide::modal', 'modal-date-select', '')
    },
    closeTelegramModal() {
      this.$root.$emit('bv::hide::modal', 'modal-telegram-date-select', '')
    },
    showEmailComposer() {
      this.shallShowEmailComposeModal = true
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'operationCalendar'
    const calendarHeight = ref(1184)

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    onMounted(async () => {
      await fetchWorkFlows()
      await nextTick()

      const divElement = document.querySelector('.fc-view-harness')
      calendarHeight.value = divElement.offsetHeight
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      eventDetail,
      clearEventData,
      // fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventDetailSidebarActive,
      updateEventInCalendar,
    } = useCalendar()

    // fetchEvents()

    function showCreateSidebar() {
      isEventHandlerSidebarActive.value = true
    }

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      eventDetail,
      clearEventData,
      refetchEvents,
      calendarOptions,
      showCreateSidebar,
      calendarHeight,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventDetailSidebarActive,
      updateEventInCalendar,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
<style>
.modal .modal-body .message-editor p {
  margin: 0 0 0 0 !important;
  font-size: 14px;
}
.label {
  font-size: 14px;
  line-height: 23px;
  color: #000;
  font-weight: 500;
}
.label-link {
  cursor: pointer;
  font-size: 14px;
  line-height: 23px;
  color: #104D9D;
  font-weight: 500;
}
.editor {
  height: 25rem;
  overflow: hidden;
}
</style>
