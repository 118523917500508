<template>
  <div>
    <b-sidebar
      id="sidebar-event-detail"
      sidebar-class="sidebar-lg"
      :visible="isEventDetailSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-detail-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div v-if="event.extendedProps">
          <div>
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                Service Request Details
              </h5>
              <div>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
            </div>

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Title
              </h5>
              <b-row>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="11"
                >
                  <p>{{ event.title ? event.title : '' }}</p>
                </b-col>
                <b-col
                  class="content-header-left"
                  cols="12"
                  md="1"
                >
                  <b-button
                    variant="flat-primary"
                    class="pl-0"
                    @click="toggleTitleModal(event.extendedProps.serviceRequestID, event.extendedProps.editableTitle)"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="EditIcon"
                      size="16"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Type
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.workflowCategory : '-' }}</p>
              <h5 class="font-weight-bolder">
                Location
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.location ? event.extendedProps.location : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Date & Time
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.formattedDate ? event.extendedProps.formattedDate : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Related File No.
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.fileNo ? event.extendedProps.fileNo : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.remarks ? event.extendedProps.remarks : '-') : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Niche No.
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.deceasedNiche ? event.extendedProps.deceasedNiche : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Pedestal No.
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.deceasedPedestal ? event.extendedProps.deceasedPedestal : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Anling Lot No.
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.deceasedAngling ? event.extendedProps.deceasedAngling : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Name of Deceased
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.deceasedName ? event.extendedProps.deceasedName : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Departed Date
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.departedDate ? event.extendedProps.departedDate : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Age & Gender
              </h5>
              <p>
                {{ event.extendedProps ? (event.extendedProps.deceasedAge ? event.extendedProps.deceasedAge : '-') : '-' }} •
                <span class="text-capitalize"> {{ event.extendedProps ? (event.extendedProps.deceasedGender ? event.extendedProps.deceasedGender : '-') : '-' }}</span>
              </p>
            </div>

            <hr class="dividerHR">
            <div v-if="(event.extendedProps.showServiceDescription && event.extendedProps.showServiceDescription == true)">
              <div
                class="px-2"
              >
                <div
                  v-if="event.extendedProps.wfCustomFields && event.extendedProps.wfCustomFields.length"
                >
                  <div
                    v-for="(opt, key) in event.extendedProps.wfCustomFields"
                    :key="key"
                  >
                    <h5
                      v-if="(opt.children && opt.children.length) || opt.value"
                      class="font-weight-bolder"
                    >
                      {{ opt.key }}
                    </h5>
                    <div v-if="(opt.children && opt.children.length) || opt.value">
                      <div v-if="opt.children">
                        <div
                          v-for="(child, indx) in opt.children"
                          :key="indx"
                        >
                          <div v-if="Array.isArray(child.value) && child.value.length">
                            <span class="d-flex">
                              <span
                                class="mr-50"
                              >
                                {{ child.key }}
                              </span>
                              <span
                                v-for="(option, index) in child.value"
                                :key="index"
                              >
                                {{ option }} <span v-if="index + 1 != child.value.length">, </span>
                              </span>
                            </span>
                          </div>
                          <div
                            v-else
                          >
                            <span
                              class="mr-50"
                            >
                              {{ child.key }}
                            </span>
                            <span
                              v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                              class="green-text"
                            >
                              {{ child.value ? child.value : '-' }}
                            </span>
                            <span v-else>
                              {{ child.value ? child.value : '' }}
                            </span>
                          </div>
                          <div
                            v-if="indx == opt.children.length - 1"
                            style="margin: 0 0 20px;"
                          />
                        </div>
                      </div>
                      <div v-else-if="Array.isArray(opt.value)">
                        <span>
                          <p
                            v-for="(option, index) in opt.value"
                            :key="index"
                          >
                            {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                          </p>
                        </span>
                      </div>
                      <div v-else>
                        <p
                          v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                          class="green-text"
                        >
                          {{ opt.value ? opt.value : '-' }}
                        </p>
                        <p v-else>
                          {{ opt.value ? opt.value : '' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                class="dividerHR"
              >
            </div>

            <div v-else-if="(event.extendedProps.oldServiceDescription && event.extendedProps.oldServiceDescription.length && !event.extendedProps.oldServiceDescription[0].id)">
              <div
                class="px-2"
              >
                <div
                  v-for="(opt, key) in event.extendedProps.oldServiceDescription"
                  :key="key"
                >
                  <h5 class="font-weight-bolder">
                    {{ opt.key }}
                  </h5>
                  <div v-if="Array.isArray(opt.value)">
                    <p>
                      <span
                        v-for="(option, index) in opt.value"
                        :key="index"
                      >
                        {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                      </span>
                    </p>
                  </div>
                  <div v-else>
                    <p>{{ (opt.value && opt.value != 0) ? opt.value : '-' }}</p>
                  </div>
                </div>
              </div>
              <hr
                class="dividerHR"
              >
            </div>

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Payment Status
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.paymentStatus ? event.extendedProps.paymentStatus : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Payment Remarks
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.paymentRemarks ? event.extendedProps.paymentRemarks : '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Send SMS Reminder to Agent
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.smsDate ? (`Sent ${event.extendedProps.smsDate}`) : 'No sms sent yet') : '-' }}</p>
              <!-- <h5 class="font-weight-bolder">
                Email Finance, Ads and Ops Team
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.emailDate ? (`Sent ${event.extendedProps.emailDate}`) : 'No email sent yet') : '-' }}</p> -->

              <div class="button__actions">
                <b-button
                  variant="primary"
                  class="mb-50"
                  @click="toggleAgentSMSModal(event.extendedProps.serviceRequestID, event.extendedProps.serviceFormID, event.extendedProps.agentContact, event.start, event.extendedProps.eventTime, event.extendedProps.workFlowName)"
                >
                  Remind Agent
                </b-button>
                <!-- <b-button
                  variant="primary"
                  @click="openEmailComposer(event.extendedProps.serviceRequestID, event.extendedProps.serviceFormID)"
                >
                  Write Email to Finance, Ads and Ops
                </b-button> -->
              </div>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Agent Name
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.agentName || '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Agent Code
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.agentCode || '-') : '-' }}</p>
              <h5 class="font-weight-bolder">
                Agent Mobile
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.agentContact ? `(65) ${event.extendedProps.agentContact}` : '-') : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <!-- Form Actions -->
            <div class="d-flex ml-auto action__block">
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="link"
                @click="hide"
              >
                Back
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="link"
                :to="{ name: 'operation-service-requests-show', params: { id: event.extendedProps ? (event.extendedProps.serviceRequestID ? event.extendedProps.serviceRequestID : 0) : 0 } }"
              >
                View Details
              </b-button>
              <b-button
                v-if="event.extendedProps.status == 'awaiting approval'"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="success"
                class="mr-75"
                @click="approveRequest(event.extendedProps.serviceRequestID)"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="CheckIcon"
                  size="16"
                />
                Approve
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="button"
                :to="{ name: 'operation-service-forms-edit', params: { id: event.extendedProps ? (event.extendedProps.serviceFormID ? event.extendedProps.serviceFormID : 0) : 0 } }"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="16"
                />
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>

    <b-modal
      id="modal-title-update"
      size="lg"
      title="Edit Calendar Title"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveTitle"
      @cancel="closeTitleModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="title"
            rows="3"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!title || requestID === ''"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-send-sms-agent"
      modal-class="sign-modal-adjust"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <h3>Remind agent about outstanding payment</h3>
      <p>Applicant hasn’t made full payment yet. Please remind Agent {{ event.extendedProps ? (event.extendedProps.agentName ? event.extendedProps.agentName : '-') : '' }} by sending SMS to <strong>(65) {{ event.extendedProps ? (event.extendedProps.agentContact ? event.extendedProps.agentContact : '-') : '' }}</strong></p>
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-agent-phone-number"
            v-model="smsText"
            placeholder="E.g. Please remind to make payment"
          />
          <small
            v-if="smsTextValidation"
            class="text-danger"
          >
            {{ smsTextError }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <b-button
            variant="link"
            @click="toggleCustomerSMSModal(event.extendedProps.serviceRequestID, event.extendedProps.serviceFormID, event.extendedProps.customerContact)"
          >
            Send to applicant instead
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <div class="demo-inline-spacing ml-auto d-flex justify-content-between">
            <b-button
              variant="flat-primary"
              @click="closeAgentSmsModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!smsText || !agentContact"
              @click="sendSms('agent')"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Send</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-send-sms-customer"
      modal-class="sign-modal-adjust"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <h3>Remind applicant about outstanding payment</h3>
      <p>Applicant hasn’t made full payment yet. Please remind <strong>applicant</strong> by sending SMS to <strong>(65) {{ event.extendedProps ? (event.extendedProps.customerContact ? event.extendedProps.customerContact : '-') : '' }}</strong></p>
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="smsText"
            placeholder="E.g. Please remind to make payment"
          />
          <small
            v-if="smsTextValidation"
            class="text-danger"
          >
            {{ smsTextError }}
          </small>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <b-button
            v-b-modal.modal-send-sms-agent
            variant="link"
          >
            Send to agent instead
          </b-button>
        </b-col>
        <b-col
          md="6"
          sm="12"
          cols="12"
        >
          <div class="demo-inline-spacing ml-auto d-flex justify-content-between">
            <b-button
              variant="flat-primary"
              @click="closeCustomerSmsModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!smsText || !customerContact"
              @click="sendSms('customer')"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Send</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BButton, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store/index'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BButton,
    BSidebar,
    BRow,
    BCol,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventDetailSidebarActive',
    event: 'update:is-event-detail-sidebar-active',
  },
  props: {
    isEventDetailSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    // updateEventInCalendar: {
    //   type: Function,
    //   default: () => {},
    // },
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      serviceForm: {},
      title: '',
      requestID: '',
      formID: '',
      smsText: '',
      agentContact: '',
      customerContact: '',
      smsTextValidation: false,
      smsTextError: '',
    }
  },
  created() {
    this.smsText = `Dear Agent,
Good day to you, please be informed that you have an outstanding payment for the following service:

Service Date: DD/MM/YY
Service Time: HH:MM
Service Details: <xxxxxx>
  
Offerings: $
Chanting: $
Others: $

Total Amount: $

Please proceed to the Customer Service counter to make the payment.

Thank you and wishing you a great day ahead. 

Nirvana Memorial Garden
${this.user.name}

[This is an automated message - Please do not reply directly to this text message].`
  },
  methods: {
    toggleTitleModal(id = '', title = '') {
      this.requestID = id
      this.title = title
      this.$root.$emit('bv::show::modal', 'modal-title-update', '')
    },
    toggleAgentSMSModal(id = '', formID = '', agentContact = '', date = '', eventTime = '', workFlowName = '') {
      this.requestID = id
      this.formID = formID
      this.agentContact = agentContact
      this.smsText = `Dear Agent,
Good day to you, please be informed that you have an outstanding payment for the following service:

Service Date: ${this.dateFormat(date)}
Service Time: ${eventTime}
Service Details: ${workFlowName}
  
Offerings: $
Chanting: $
Others: $

Total Amount: $

Please proceed to the Customer Service counter to make the payment.

Thank you and wishing you a great day ahead. 

Nirvana Memorial Garden
${this.user.name}

[This is an automated message - Please do not reply directly to this text message].`
      this.$root.$emit('bv::show::modal', 'modal-send-sms-agent', '')
    },
    toggleCustomerSMSModal(id = '', formID = '', customerContact = '') {
      this.requestID = id
      this.formID = formID
      this.customerContact = customerContact
      this.$root.$emit('bv::show::modal', 'modal-send-sms-customer', '')
    },
    closeCustomerSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-customer', '')
    },
    closeAgentSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-agent', '')
    },
    openEmailComposer(id = '', formID = '') {
      this.requestID = id
      this.formID = formID
      this.$emit('update:is-event-detail-sidebar-active', false)
      this.$emit('showEmailComposer')
    },
    sendSms(type) {
      let phone = ''
      if (type === 'agent') {
        phone = this.agentContact
        this.closeAgentSmsModal()
      } else {
        phone = this.customerContact
        this.closeCustomerSmsModal()
      }

      this.$http
        .post('operation/service-requests/send-sms', { phone, message: this.smsText, serviceRequest: this.requestID })
        .then(response => {
          // this.serviceForm = response.data.data || {}
          const updatedEventData = {

            id: this.requestID,
            extendedProps: {
              smsDate: this.dateFormatWithTime(response.data.data.smsDate),
            },
          }
          const propsToUpdate = []
          const extendedPropsToUpdate = ['smsDate']
          this.$emit('updateEventInCalendar', updatedEventData, propsToUpdate, extendedPropsToUpdate)
          this.$emit('update:is-event-detail-sidebar-active', false)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveTitle(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$http
        .post('operation/service-requests/update-title', { title: this.title, requestID: this.requestID })
        .then(response => {
          let title = ''
          if (response.data.data.status === 'awaiting approval') {
            title = `[AWAITING APPROVAL] ${response.data.data.updatedTitle}${response.data.data.paymentStatus ? ` - ${response.data.data.paymentStatus}` : ''}`
          } else {
            title = `${response.data.data.updatedTitle}${response.data.data.paymentStatus ? ` - ${response.data.data.paymentStatus}` : ''}`
          }
          const updatedEventData = {
            id: response.data.data._id,
            title,
            extendedProps: {
              editableTitle: response.data.data.updatedTitle,
            },
          }
          const propsToUpdate = ['title']
          const extendedPropsToUpdate = ['editableTitle']
          this.$emit('updateEventInCalendar', updatedEventData, propsToUpdate, extendedPropsToUpdate)
          this.$emit('update:is-event-detail-sidebar-active', false)
          // this.updateEventInCalendar(updatedEventData, propsToUpdate, extendedPropsToUpdate)
          this.closeTitleModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    approveRequest(id) {
      this.$http
        .patch(`operation/calendar/service-requests/${id}/approve`, { url: window.location.origin })
        .then(response => {
          const updatedEventData = {
            id: response.data.data._id,
            title: response.data.data.title,
            backgroundColor: response.data.data.backgroundColor,
            extendedProps: {
              editableTitle: response.data.data.updatedTitle,
              status: response.data.data.status,
            },
          }
          const propsToUpdate = ['title', 'backgroundColor']
          const extendedPropsToUpdate = ['editableTitle', 'status']
          this.$emit('updateEventInCalendar', updatedEventData, propsToUpdate, extendedPropsToUpdate)
          this.$emit('update:is-event-detail-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Service Request Approved',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeTitleModal() {
      this.$root.$emit('bv::hide::modal', 'modal-title-update', '')
    },
  },
}
</script>
