<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div
      class="p-2"
      style="padding-right: 0 !important;"
    >
      <div class="">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Service Request Type</span>
        </h5>
        <b-form-group
          :style="{ 'max-height': height+'px', 'overflow': 'auto' }"
          style="padding-right: 21px !important;"
        >
          <b-form-checkbox
            v-model="checkAll"
            class="mbc-10 select-all custom-control-unique-selector"
            :style="{ '--bg-color': '#BABFC71F' }"
          >
            Select all
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <div
              v-for="category in calendarOptions"
              :key="category._id"
            >
              <b-form-checkbox
                v-for="item in category.workFlows"
                :key="item._id"
                name="event-filter"
                :value="item._id"
                class="mbc-10 custom-control-unique-selector"
                :style="{ '--bg-color': item.name == 'Awaiting approval' ? '#BABFC71F' : category.color }"
              >
                <span
                  v-if="item.name == 'Awaiting approval'"
                  :style="{ 'color': category.color }"
                >
                  {{ item.name }}
                </span>
                <span v-else>{{ item.name }}</span>
              </b-form-checkbox>
            </div>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    height: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
      awaitingApproval,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
      awaitingApproval,
    }
  },
}
</script>
